<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('news.edit','编辑新闻'):$l('news.add','新增新闻')"
    width="800px"
    top="3vh"
    :modal="false"
    @close="onClose">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      label-width="80px">
      <el-form-item :label="$l('news.title','标题')" prop="title" :rules="$rule.notNull">
        <el-input v-model="model.title" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <div id="editor"></div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  import wangEditor from "wangeditor";
  import auth from "@/util/loginUtil";

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: true,
        submitLoading: false,
        editor: null,
        model: {
          id: 0,
          title: "",
          content: "",
        },
      };
    },
    beforeDestroy() {
      this.editor.destroy();
      this.editor = null;
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.initEditor();
      },
      initEditor() {
        this.$nextTick(() => {
          if (!this.editor) {
            const editor = new wangEditor("#editor");
            //配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.onchange = (newHtml) => {
              this.model.content = newHtml;
            };
            //上传地址
            editor.config.uploadImgServer = window.config.pictureUrl + "files/uploadFullUrl/device/image";
            editor.config.uploadFileName = "file";
            editor.config.uploadImgMaxLength = 1;
            //上传头
            editor.config.uploadImgHeaders = {
              Authorization: auth.getToken(),
            };
            editor.config.uploadImgHooks = {
              // 图片上传并返回了结果，但图片插入时出错了
              fail: function(xhr, editor, resData) {
                console.log("fail", resData);
              },
              // 上传图片出错，一般为 http 请求的错误
              error: function(xhr, editor, resData) {
                console.log("error", xhr, resData);
              },
              // 上传图片超时
              timeout: function(xhr) {
                console.log("timeout", xhr);
              },
              // 图片上传并返回了结果，想要自己把图片插入到编辑器中
              // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
              customInsert: function(insertImgFn, result) {
                // result 即服务端返回的接口
                console.log("customInsert", result);
                // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
                insertImgFn(result.data);
              },
            };
            //创建编辑器
            editor.create();
            this.editor = editor;
          }
          this.getData();
        });
      },
      onClose() {
        this.editor.txt.clear();
        this.model = {
          id: 0,
          title: "",
          content: "",
        };
        this.$refs.form.resetFields();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`basicNews/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            this.editor.txt.html(data.content);
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("basicNews", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
