<template>
  <div class="device-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basicNews">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('news.title','新闻标题')">
            <el-input v-model.trim="filter.title" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="title" :label="$l('news.title','新闻标题')"></el-table-column>
      <el-table-column prop="createBy" :label="$l('news.createBy','创建人')" align="center"></el-table-column>
      <el-table-column prop="createTime" :label="$l('common.createTime','创建时间')" align="center"></el-table-column>
      <el-table-column prop="updateTime" :label="$l('common.updateTime','修改时间')" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="140px">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./NewsEdit.vue";

  export default {
    components: {EditPage},
    data() {
      return {
        filter: {
          title: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.deviceNo}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basicNews", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.deviceNo + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
